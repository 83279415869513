<template>
  <div class="box">
    <div class="list-box">
      <div class="list" @click="toQa">
        <img src="@/img/message/icon-1.png" class="icon" />
        <div class="list-right">
          <div class="title-box">
            <div class="title">智能客服</div>
            <div class="time"></div>
          </div>
          <div class="info">
            <!-- {{ xtMsg.title }} -->
          </div>
        </div>
      </div>
      <div class="list" @click="toChat">
        <div style="position: relative">
          <img src="@/img/message/icon-2.png" class="icon" />
          <div class="num" v-show="chartData.num != 0">{{ chartData.num }}</div>
        </div>
        <div class="list-right">
          <div class="title-box">
            <div class="title">专属客服</div>
            <div class="time">{{ chartData.time }}</div>
          </div>
          <div class="info">
            <!-- {{ xtMsg.title }} -->
          </div>
        </div>
      </div>
      <div class="list" @click="toInfo">
        <img src="@/img/message/icon-3.png" class="icon" />
        <div class="list-right">
          <div class="title-box">
            <div class="title">系统通知</div>
            <div class="time">{{ xtMsg.gmtCreate }}</div>
          </div>
          <div class="info">
            {{ xtMsg.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      xtMsg: {},
      chartData: {},
    };
  },
  created() {
    this.chartData = JSON.parse(localStorage.getItem("msg"));
    this.$http.get(url.getStationMsg).then((res) => {
      if (res.code == 200) {
        this.$http.get(url.listAndStation).then((data) => {
          this.xtMsg = data.data.content[0].appStationMsg;
        });
      }
    });
  },
  methods: {
    toQa() {
      this.$router.push({
        path: "qa",
      });
    },
    toChat() {
      this.$router.push({
        path: "chat",
      });
    },
    toInfo() {
      this.$router.push({
        path: "messageSystem",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  padding-top: 66px;
  padding-bottom: 50px;
  min-height: calc(100vh - 120px);
  .list-box {
    width: 375px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    .list {
      width: 360px;
      height: 68px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 43px;
        height: 43px;
      }
      .num {
        background: #ea4d3e;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
        position: absolute;
        right: -5px;
        top: -5px;
      }
      .list-right {
        width: 300px;
        height: 43px;
        .title-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            color: #101010;
            font-size: 14px;
            font-weight: bold;
          }
          .time {
            color: #999999;
            font-size: 11px;
          }
        }
        .info {
          width: 100%;
          color: #999999;
          font-size: 11px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
